.skills-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--var-card-dark);
  min-width: 10rem;
  max-width: 10rem;
  min-height: 10rem;
  max-height: 10rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 5rem;
  margin-top: 5rem;
  margin-left: 3rem;
  padding-top: 1rem;
  transition: 300ms ease-in-out;
}

.skills-card:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.skills-card img {
  width: 30%;
  margin: 2rem;
  margin-bottom: 1rem;
}

.skills-card h3 {
  color: var(--var-soft-blue);
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  z-index: 10;
}

@media (max-width: 768px) {
  .skills-card {
    min-width: 8rem;
    max-width: 8rem;
    min-height: 8rem;
    max-height: 8rem;
  }

  .skills-card img {
    width: 40%;
    margin: 1rem;
    margin-bottom: 0.5rem;
  }

  .skills-card h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}
