.edContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
  color: var(--var-soft-blue);
  background-color: var(--var-main-darkest);
}

.education {
  max-width: 30vw;
}

.education h1 {
  font-size: 3em;
  margin-bottom: 30px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  max-width: auto;
  height: 75vh;
}

@media (max-width: 768px) {
  .edContainer {
    flex-direction: column;
    padding: 20px;
  }

  .education {
    max-width: 100%;
    font-size: 0.75em;
  }

  .image-container img {
    max-width: 350px;
    max-height: 350px;
  }
}
