.exContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
  background-color: var(--var-main-darkest);
  color: var(--var-soft-blue);
}

.experience {
  max-width: 35vw;
}

.experience h1 {
  font-size: 3em;
  color: var(--var-main-title);
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  max-width: auto;
  height: 70vh;
}

@media (max-width: 768px) {
  .exContainer {
    flex-direction: column;
    padding: 20px;
  }

  .experience {
    max-width: 100%;
    font-size: 0.75em;
  }

  .image-container img {
    max-width: 300px;
    max-height: 300px;
  }
}
