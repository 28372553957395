.nb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 8px;
  width: 100%;
  z-index: 800;
}

#nbImg {
  font-size: 3.3rem;
  margin-right: 20px;
  color: var(--var-main-title);
  cursor: pointer;
  transition: all 0.2s ease;
}
#nbImg:hover {
  color: var(--var-soft-blue);
  transition: all 0.2s ease;
  transform: translateX(-10px);
}

h1 {
  font-size: 2rem;
  margin-left: 10px;
  color: var(--var-main-title);
}

.popup-navbar {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  background-color: #010220;
  box-shadow: 0 2px 5px rgba(9, 5, 38, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
}

.popup-navbar ul {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;
  margin-left: 0;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}

.popup-navbar li {
  margin: 14px;
}

.popup-navbar a {
  color: var(--var-main-title);
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.popup-navbar {
  cursor: pointer;
}
.navIcons {
  font-size: 1.8rem;
  color: var(--var-main-title);
  cursor: pointer;
}
.popup-navbar a:hover,
h6:hover {
  color: var(--var-soft-blue);
  cursor: pointer;
}
h6 {
  font-size: 1rem;
  color: var(--var-main-title);
  margin-right: 10px;
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  cursor: pointer;
}

@media (max-width: 768px) {
  #nbImg {
    font-size: 2.5rem;
    margin-right: 10px;
    margin-left: 10px;
  }

  h1 {
    font-size: 1.8rem;
    margin-left: 15px;
  }

  .navIcons {
    font-size: 1.2rem;
  }
  h6 {
    font-size: 0.8rem;
  }
}

@media (max-width: 520px) {
  h1 {
    font-size: 1.32rem;
    margin-left: 10px;
  }
  #nbImg {
    margin-right: 15px;
    margin-left: 15px;
  }
}
