.skills-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--var-main-darkest);
}

.skills-container h1 {
  font-size: 3em;
  color: var(--var-main-title);
}

@media (max-width: 768px) {
  .skills-container h1 {
    font-size: 2em;
  }
}
