@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap");

:root {
  --var-soft-blue: hsl(215, 51%, 70%);
  --var-cyan: hsl(178, 100%, 50%);
  --var-main-darkest: hsl(217, 54%, 11%);
  --var-card-dark: hsl(216, 50%, 16%);
  --var-line-dark: hsl(215, 32%, 27%);
  --var-heading: normal normal 600 2.5em/3em "Outfit", sans-serif;
  --var-small-heading: normal normal 400 1em/1em "Outfit", sans-serif;
  --var-para: normal normal 300 1em/1.55em "Outfit", sans-serif;
  --var-main-title: #3f72af;
}
.body {
  background-color: var(--var-main-darkest);
  color: var(--var-main-darkest);
  margin: 10px;
  padding: 10px;
  box-sizing: border-box;
  overflow-x: hidden;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  color: var(--var-soft-blue);
  background-color: var(--var-main-darkest);
}

img {
  width: 100%;
  border-radius: 15px;
  display: block;
}

a {
  color: inherit;
}

h1 {
  font-size: 3em;
  color: var(--var-main-title);
}

h2 {
  font: var(--var-small-heading);
  color: var(--var-soft-blue);
}

p {
  font: var(--var-para);
  color: var(--var-soft-blue);
}
