.who-i-am {
  max-width: 600px;
}

.who-i-am p {
  font-size: 1.2em;
  line-height: 1.6em;
  color: var(--var-soft-blue);
}
.who-i-am h2 {
  font-size: 3em;
  color: var(--var-main-title);
}

@media (max-width: 768px) {
  .who-i-am {
    margin-top: 20px;
  }
  .who-i-am h2 {
    font-size: 2.5em;
  }
  .who-i-am p {
    font-size: 1em;
  }
  .container {
    flex-direction: column;
  }
  .container img {
    max-width: 300px;
    max-height: 300px;
  }
}
