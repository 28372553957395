.projectParent {
  background-color: var(--var-main-darkest);
  color: var(--var-soft-blue);
  padding: 2.5em;
  height: 100%;
}
.projectParent h1 {
  font-size: 3em;
  color: var(--var-main-title);
  align-items: center;
  justify-content: center;
  display: flex;
}
.viewAllButton {
  width: 1.8vw;
  height: 1.8vw;
  background-color: var(--var-main-darkest);
  color: var(--var-soft-blue);
  padding: 1em;
  border: 1px solid var(--var-soft-blue);
  border-radius: 18%;
  cursor: pointer;
  font-family: var(--var-small-heading);
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1em;
}

@media (max-width: 768px) {
  .projectParent {
    padding: 1em;
  }
  .projectParent h1 {
    font-size: 2em;
  }
  .viewAllButton {
    width: 3vw;
    height: 3vw;
    padding: 0.5em;
    font-size: 1em;
  }
}
