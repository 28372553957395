.card-item {
  display: flex;
  align-items: center;
  background-color: var(--var-card-dark);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}
.card-item:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  scale: 1.06;
  transition: all 0.2s;
}

.card-icon {
  margin-right: 20px;
}

.card-icon img {
  width: 70px;
  height: 70px;
  filter: invert(0.75) hue-rotate(100deg);
}

.card-content h2 {
  color: #afafaf;
  margin: 0;
  font-size: 1.2em;
}

.card-content h3 {
  margin: 5px 0;
  font-size: 1.5em;
}

.card-content p {
  margin: 0;
  font-size: 1.2em;
  color: #d3d3d3;
}
