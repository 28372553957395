#home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 500;
  box-sizing: border-box;
}

.left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 37%;
  background-color: var(--var-main-darkest);
  font-size: 1.5rem;
  font-weight: 500;
  padding: 10px;
  box-sizing: border-box;
}

.hImg {
  width: 100%;
  max-width: 420px;
  height: auto;
  border-radius: 50%;
  position: relative;
  left: 52%;
}

.hIcon {
  cursor: pointer;
  margin: 5px;
  color: var(--var-main-title);
  transition: all 0.2s;
}

.hIcon:hover {
  color: var(--var-soft-blue);
  transition: all 0.2s;
}

#hLogos {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 0;
  bottom: 0;
  padding: 42px;
}

.right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 100vh;
  width: 63vw;
  background-color: var(--var-card-dark);
  color: black;
  padding: 40px;
  box-sizing: border-box;
}

.hDesc {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 40px;
  width: 80%;
  text-align: center;
}

.hDesc {
  width: 70%;
}

.hName {
  font-size: 4.5vw;
  font-weight: 700;
  margin-bottom: 10px;
}

.rcAlign {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-size: 1.5rem;
  font-weight: 500;
  box-sizing: border-box;
  padding: 10px;
  width: 70%;
}

.hButton {
  background-color: var(--var-main-title);
  border: none;
  color: rgb(0, 0, 0);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font: var(--var-small-heading);
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.2s;
}

.hButton:hover {
  background-color: var(--var-soft-blue);
  scale: 1.06;
  transition: all 0.2s;
}

.hTitle {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 20px;
}
.hIcon {
  font-size: 3.5rem;
  margin: 5px;
  color: var(--var-main-title);
  transition: all 0.2s;
}
.rightPara {
  font-weight: 400;
  line-height: 0.5;
  margin-bottom: 40px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Media Queries */

@media (max-width: 768px) {
  #home {
    flex-direction: column;
  }

  .left-container {
    width: 100%;
    height: 30vh;
  }

  .hImg {
    left: 0;
    margin-bottom: 20px;
    width: 30%;
    position: relative;
    top: 60%;
  }

  .right-container {
    width: 100%;
    padding: 0;
    height: 70vh;
    align-items: center;
  }
  .rightPara {
    width: 80%;
    justify-content: center;

    text-align: center;
  }
  .hButton {
    font-size: 1rem;
  }

  .rcAlign {
    width: 100%;
  }
  #hLogos {
    padding: 20px;
  }
  .hIcon {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .hName {
    font-size: 6vw;
  }

  .hDesc {
    font-size: 1rem;
  }

  .rightPara {
    width: 90%;
  }
  .right-container {
    height: 80vh;
  }
  .left-container {
    height: 20vh;
  }
}
