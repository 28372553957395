.pc-card-container {
  width: auto;
  max-width: 300px;
  margin: 2em 1em;
  background-color: var(--var-card-dark);
  border-radius: 15px;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
}
.pc-card-container:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  scale: 1.06;
  transition: all 0.2s;
}

.pc-flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pc-main-content h2 {
  font: var(--var-small-heading);
  font-size: 2em;
  color: var(--var-soft-blue);
}

.pc-coin-base,
.pc-time-left,
.pc-card-attribute {
  display: flex;
  align-items: center;
  padding: 1em 1em;
}

.pc-hero-image-container {
  position: relative;
  display: block;
}
a {
  text-decoration: none;
}

.pc-hero-image-container::after {
  content: "";
  background-image: url("https://i.postimg.cc/9MtT4GZY/view.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5rem;
  background-color: hsla(223, 53%, 36%, 0.369);
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.pc-hero-image-container:hover::after {
  opacity: 1;
}

.pc-small-image {
  width: 2em;
  margin-right: 0.5em;
}

@media (max-width: 768px) {
  .pc-card-container {
    max-width: 100%;
  }
  .pc-main-content h2 {
    font-size: 1.5em;
  }
  .pc-hero-image-container::after {
    background-size: 3rem;
  }
  .pc-small-image {
    width: 1.5em;
  }
}
